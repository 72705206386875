import React, { useState, useEffect } from 'react';
import './NewMissionForm.css'; // Import du fichier de style CSS
import axios from 'axios';
import csv from 'csvtojson';

const NewMissionForm = () => {
    //gestion des états et tests du formulaire 
    const [selectedAffaire, setSelectedAffaire] = useState(''); //Etat permettant de savoir si on a choisi une affaire, pour afficher la suite du formulaire ou pas
    const [selectedDomaines, setSelectedDomaines] = useState([]); //Etat permettant de remettre à 0 les champs quand on décoche un domaine
    const [domaineDetails, setDomaineDetails] = useState([]); // Etat gérant la validité des options de pourcentage
    const [formIsValid, setFormIsValid] = useState(false); // Etat de validité du formulaire
    const [selectedTypeMission, setSelectedTypeMission] = useState(''); // Nouvel état pour stocker le type de mission sélectionné
    const [tjmVendu, setTjmVendu] = useState(''); // Ajout de l'état pour le TJM vendu
    const [offersData, setOffersData] = useState([]);
    const [missionsData, setMissionsData] = useState([]);


    useEffect(() => {
        // URL publique pour offers.json
        const offersUrl = 'https://firebasestorage.googleapis.com/v0/b/dataplaform.appspot.com/o/offers.json?alt=media&token=your-offers-token';

        // URL publique pour new_missions.csv
        const missionsUrl = 'https://firebasestorage.googleapis.com/v0/b/prod_new_missions/o/new_missions.csv?alt=media&token=ff128601-3246-4f1d-9199-5a0a25deea18';

        // Requête GET vers l'URL de offers.json
        axios.get(offersUrl)
            .then(response => {
                console.log('Offers Data:', response.data); // Vérifiez les données récupérées
                setOffersData(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données de offers.json :', error);
            });

        // Requête GET à l'URL de new_missions.csv
        axios.get(missionsUrl, { responseType: 'blob' })
            .then(response => response.data.text())
            .then(data => {
                return csv().fromString(data);
            })
            .then((jsonObj) => {
                console.log('Missions Data:', jsonObj); // Vérifiez les données transformées
                setMissionsData(jsonObj);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données de new_missions.csv :', error);
            });
    }, []);


    const getBlockOptionsForDomaine = (domaine) => {
        const domainOffers = offersData.filter(
            (offer) => offer.Domaine === domaine
        );
        const uniqueBlocks = [...new Set(domainOffers.map((offer) => offer.Offre))];
        return uniqueBlocks;
    };
    //Composant permettant de mettre à jour la variable "SelectedAffaire"
    const handleAffaireChange = (event) => {
        setSelectedAffaire(event.target.value);
    };

    //Composant Permettant de mettre à jour la variable "SelectedTypeMission" et de faire apparître le TJM vendu pour interne et mixte
    const handleTypeMissionChange = (event) => {
        setSelectedTypeMission(event.target.value); // Mettre à jour le type de mission sélectionné
        if (event.target.value === 'interne' || event.target.value === 'mixte') {
            setTjmVendu('');
        }
    };

    //Composant permettant de mettre à jour la checkbox des domaines au changement, et de remettre à 0 les champs (pourcentages)
    const handleDomaineChange = (event) => {
        const selectedDomaine = event.target.value;
        setSelectedDomaines((prevDomaines) =>
            prevDomaines.includes(selectedDomaine)
                ? prevDomaines.filter((domaine) => domaine !== selectedDomaine)
                : [...prevDomaines, selectedDomaine]
        );
    };
    //Remet les champs du domaine à 0 si le domaine est décoché
    const handleDomaineDetailsChange = (domaine, type, value) => {
        const updatedDomaineDetails = [...domaineDetails];
        const index = updatedDomaineDetails.findIndex((detail) => detail.domaine === domaine);
        if (index !== -1) {
            updatedDomaineDetails[index][type] = value;
        } else {
            updatedDomaineDetails.push({ domaine, [type]: value });
        }
        setDomaineDetails(updatedDomaineDetails);
    };
    //Gestion des pourcentages Offre principale/scondaire
    const validateDomaineDetails = () => {
        for (const domaine of selectedDomaines) {
            const domaineDetailsForDomaine = domaineDetails.filter((detail) => detail.domaine === domaine);
            const totalPercentageOffrePrincipale = domaineDetailsForDomaine.reduce(
                (sum, detail) => sum + Number(detail.percentageOffrePrincipale || 0),
                0
            );
            const totalPercentageOffreSecondaire = domaineDetailsForDomaine.reduce(
                (sum, detail) => sum + Number(detail.percentageOffreSecondaire || 0),
                0
            );

            if (totalPercentageOffrePrincipale + totalPercentageOffreSecondaire !== 100) {
                return false;
            }
        }

        return true;
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        // Vérifier que le champ "TJM vendu" n'est pas vide si "Interne" ou "Mixte" sont choisis
        if ((selectedTypeMission === 'interne' || selectedTypeMission === 'mixte') && (!tjmVendu)) {
            console.log('Le champ "TJM vendu" doivent être remplis pour le type de mission "Interne" ou "Mixte".');
            setFormIsValid(false); // Le formulaire n'est pas valide
            return;
        }
        // Vérifier que la somme des pourcentages ne dépasse pas 100%
        const totalPercentage = domaineDetails.reduce((sum, detail) => sum + Number(detail.percentage), 0);
        if (totalPercentage !== 100) {
            console.log('La somme des pourcentages doit être égale à 100%.');
            setFormIsValid(false); // Le formulaire n'est pas valide
            return;
        }
        // Vérifier que la somme des pourcentages pour chaque domaine sur l'offre principale et l'offre secondaire est égale à 100%
        if (!validateDomaineDetails()) {
            console.log('La somme des pourcentages sur l\'offre principale et l\'offre secondaire doit être égale à 100% pour chaque domaine.');
            setFormIsValid(false); // Le formulaire n'est pas valide
            return;
        }
        // Effectuer les actions nécessaires lors de la soumission du formulaire
        console.log('Formulaire soumis !', {
            selectedAffaire,
            selectedDomaines,
            domaineDetails,
            tjmVendu,
        });
        setFormIsValid(true); // Le formulaire est valide
    };

    //Affichage du formulaire
    return (
        <form onSubmit={handleSubmit} style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '10px', padding: '50px' }}>
            <h1 style={{ color: '#53bccb', textAlign: 'center' }}>Formulaire nouvelles missions</h1>
            <p>
                Attention : Vous êtes responsable des informations que vous saisissez. Une fois le formulaire soumis, aucune modification ne peut être effectuée.
            </p>

            {/* Début du champ Affaire */}
            <div>
                <label htmlFor="affaire">Affaire :</label>
                <select
                    id="affaire"
                    value={selectedAffaire}
                    onChange={handleAffaireChange}
                >
                    <option value="">Sélectionnez une valeur</option>
                    {missionsData.map((mission, index) => (
                        <option key={index} value={mission.Affaire}>
                            {mission.Affaire}
                        </option>
                    ))}
                </select>
            </div>
            {selectedAffaire && (
                <>
                    {/* Début du champ facturation */}
                    <div>
                        <label htmlFor="facturation">Facturation :</label>
                        <select id="facturation" defaultValue="default">
                            <option value="default" disabled>Sélectionnez une valeur</option>
                            <option value="forfait">Forfait</option>
                            <option value="regie">Régie</option>
                        </select>
                    </div>
                    {/* Début du champ TypeMission */}
                    <div>
                        <label htmlFor="typeMission">Type de mission :</label>
                        <select id="typeMission" value={selectedTypeMission} onChange={handleTypeMissionChange}>
                            <option value="" disabled>Sélectionnez une valeur</option>
                            <option value="interne">Interne</option>
                            <option value="externe">Externe</option>
                            <option value="mixte">Mixte</option>
                        </select>
                    </div>

                    {/* Affichage conditionnel de l'input TJM vendu */}
                    {(selectedTypeMission === 'interne' || selectedTypeMission === 'mixte') && (
                        <>
                            <div >
                                <label htmlFor="tjmVendu">TJM vendu :</label>
                                <input
                                    type="number"
                                    id="tjmVendu"
                                    value={tjmVendu}
                                    onChange={(e) => setTjmVendu(e.target.value)}
                                    min="0"
                                />
                            </div>
                        </>
                    )}
                    {/* Début de la checkbox Domaine */}
                    <div>
                        <p>Domaine :</p>
                        <label>
                            <input
                                type="checkbox"
                                value="DW"
                                checked={selectedDomaines.includes('DW')}
                                onChange={(event) => handleDomaineChange(event)}
                            />
                            DW
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value="C&I"
                                checked={selectedDomaines.includes('C&I')}
                                onChange={(event) => handleDomaineChange(event)}
                            />
                            C&I
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value="IOT"
                                checked={selectedDomaines.includes('IOT')}
                                onChange={(event) => handleDomaineChange(event)}
                            />
                            IOT
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                value="N&S"
                                checked={selectedDomaines.includes('N&S')}
                                onChange={(event) => handleDomaineChange(event)}
                            />
                            N&S
                        </label>
                    </div>
                </>
            )}
            {/* Début des paramètres de domaine*/}
            {selectedDomaines.map((domaine) => (
                <div key={domaine}>
                    <label htmlFor={`percentage-${domaine}`}>{`${domaine} : Pourcentage sur le chiffre d'affaire de la mission`}</label>
                    <input
                        type="number"
                        min={0}
                        max={100}
                        id={`percentage-${domaine}`}
                        onChange={(event) =>
                            handleDomaineDetailsChange(domaine, 'percentage', event.target.value)
                        }

                    />
                    <label htmlFor={`offrePrincipale-${domaine}`}>{`${domaine} : Offre Principale`}</label>
                    <select
                        id={`offrePrincipale-${domaine}`}
                        defaultValue="default"
                        onChange={(event) =>
                            handleDomaineDetailsChange(domaine, 'offrePrincipale', event.target.value)
                        }
                    >
                        <option value="default" disabled>
                            Sélectionnez une valeur
                        </option>
                        {getBlockOptionsForDomaine(domaine).map((Offre) => (
                            <option key={Offre} value={Offre}>
                                {Offre}
                            </option>
                        ))}
                    </select>
                    {domaineDetails.find((detail) => detail.domaine === domaine && detail.offrePrincipale) && (
                        <>
                            <label htmlFor={`percentageOffrePrincipale-${domaine}`}>{`${domaine} : Pourcentage sur l'offre principale`}</label>
                            <input
                                type="number"
                                min={0}
                                max={100}
                                id={`percentageOffrePrincipale-${domaine}`}
                                onChange={(event) =>
                                    handleDomaineDetailsChange(domaine, 'percentageOffrePrincipale', event.target.value)
                                }
                            />
                            <label htmlFor={`offreSecondaire-${domaine}`}>{`${domaine} : Offre secondaire`}</label>
                            <select
                                id={`offreSecondaire-${domaine}`}
                                defaultValue="default"
                                onChange={(event) =>
                                    handleDomaineDetailsChange(domaine, 'offreSecondaire', event.target.value)
                                }
                            >
                                <option value="default" disabled>Sélectionnez une valeur</option>
                                {getBlockOptionsForDomaine(domaine).map((Offre) => (
                                    <option key={Offre} value={Offre}>
                                        {Offre}
                                    </option>
                                ))}
                            </select>
                            {domaineDetails.find((detail) => detail.domaine === domaine && detail.offreSecondaire) && (
                                <>
                                    <label htmlFor={`percentageOffreSecondaire-${domaine}`}>{`${domaine} : Pourcentage sur l'offre secondaire`}</label>
                                    <input
                                        type="number"
                                        min={0}
                                        max={100}
                                        id={`percentageOffreSecondaire-${domaine}`}
                                        onChange={(event) =>
                                            handleDomaineDetailsChange(domaine, 'percentageOffreSecondaire', event.target.value)
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            ))}
            {/* Submit */}
            {formIsValid && <p>Le formulaire est validé.</p>}
            <button type="submit">Valider</button>
        </form>
    );
};

export default NewMissionForm;